import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

export function deep_links(router) {
    if (Capacitor.getPlatform() != 'android') return

    // If it's android, let's see what happns with this.
    App.addListener('appUrlOpen', function (event) {
        // Example url: https://produuz.it/tabs/tabs2
        // slug = /tabs/tabs2
        const slug = event.url.split('.it/app').pop();
        console.log("[i] Deeplink detected: ", slug);

        // We only push to the route if there is a slug present
        if (slug) {
            router.push({
                path: slug,
            });
        }
    });
    console.log("[i] Added event listener for deeplinks.")
}


export function isMobile() {
    // Check if running in a Capacitor environment
    if (Capacitor.isNativePlatform()) {
        const platform = Capacitor.getPlatform();
        return platform === 'ios' || platform === 'android';
    }
    // Fallback to user agent check for browser environment
    return /Mobi|Android/i.test(navigator.userAgent);
}




