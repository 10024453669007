function nameSort(a, b) {
    if ([a, b].includes(undefined)) return 0
    else return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: "base",
    });
}

function by_revision(a, b) {
    return a.revision - b.revision
}

function by_dwg_number(a, b) {
    let na = a.meta?.dwg_number || 0
    let nb = b.meta?.dwg_number || 0
    return na - nb
}


function by_name(a, b) {
    if (a.name > b.name) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function by_username(a, b) {
    if (a.username > b.username) {
        return 1;
    }
    if (a.username < b.username) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function by_short_name_number(a, b) {
    const an = a.short_name.split(" - ")[1]
    const bn = b.short_name.split(" - ")[1]
    if (an > bn) {
        return 1;
    }
    if (an < bn) {
        return -1;
    }
    // a must be equal to b
    return -1;
}

function by_created(a, b) {
    if (a.created > b.created) {
        return 1;
    }
    if (a.created < b.created) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

// Function that gets a list of "ifc" objects and removes duplicate names, keeping
// the one with the highest revision number which is on meta.revision
function remove_duplicate_cfs_by_names(list) {
    let names = {}
    list.forEach((item) => {
        if (names[item.name] === undefined) {
            names[item.name] = item
        } else {
            if (names[item.name].meta.revision < item.meta.revision) {
                names[item.name] = item
            }
        }
    })
    return Object.values(names)
}


export {
    nameSort, by_revision, by_dwg_number,
    by_name, by_username, by_short_name_number, by_created,
    remove_duplicate_cfs_by_names,
}