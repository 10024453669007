<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn icon variant="text" v-bind="props">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item @click="dialog = true">
                <template v-slot:prepend>
                    <v-icon icon=mdi-broom />
                </template>
                <v-list-item-title>Clean Chat</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-dialog v-model="dialog" max-width="400px">
        <v-card>
            <v-card-title class="headline"> {{ t("confirm_delete") }} </v-card-title>
            <v-card-text> {{ t("confirm_delete_text") }} </v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog = false">Cancel</v-btn>
                <v-btn color="accent" text @click="confirm_delete">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script setup>
import { useMailStore } from "@/stores/mail";
import { usePitStoreBasics } from "@/stores/app_common";
import { backend_api_v2 } from "/src/utils/apiv2";
import { computed, ref } from "vue";

const { t } = usePitStoreBasics();
const dialog = ref(false)
const mail_store = useMailStore()
const props = defineProps({
    chat: Object,
})
const contact = computed(() => props.chat.contact)

const confirm_delete = () => {
    backend_api_v2.post(`/mail/messages/delete_all_for_contact/`, {
        contact_id: contact.value,
    })
        .then(() => {
            mail_store.cleanChat(contact.value)
            dialog.value = false;
        });
}
</script>