<template>
    <div ref="chat" class="overflow-y-auto" style="max-height: calc(100vh - 100px); position: relative;">
        <div class="mt-3"></div>
        <div v-if="!contact" class="d-flex flex-column">
            <v-chip color="grey" size="small" class="my-auto mx-auto">
                <v-icon left> mdi-arrow-left </v-icon>
                Select a chat
            </v-chip>
        </div>
        <div class="d-flex flex-column" v-else>
            <v-btn @click="load_more" variant="outlined" rounded="xl" size="small" class="mx-auto my-2">
                <v-icon left>mdi-arrow-up</v-icon> load older
            </v-btn>
            <v-chip v-if="loading_chat" color="grey" dark size="small" class="mb-3 mx-auto">
                loading...
            </v-chip>
        </div>
        <ChatMessage v-for="(msg, i) in chat_messages" :msg="msg" :key="i" />
    </div>
</template>



<script>
import ChatMessage from "./ChatMessage";
import { useMailStore } from "@/stores/mail";
import { usePitStoreBasics } from "@/stores/app_common";

export default {
    setup() { return { ...usePitStoreBasics(), mail_store: useMailStore() } },
    components: { ChatMessage },
    props: ["contact"],
    data: () => ({
        loading_chat: false,
        waiting_for_read_list: [],
        interval: null,
        last_count: 30,
    }),
    computed: {
        chat() {
            return this.mail_store.getChatByContactId(this.contact);
        },
        chat_messages() {
            return this.chat ? this.chat.messages : [];
        },
    },
    watch: {
        chat_messages(cm, ocm) {
            if (ocm.length != cm.length)
                setTimeout(() => this.scrollChatToBottom(), 100);
            cm.forEach((msg) => {
                if (
                    !msg.readed && msg.uuid &&
                    (!msg.contact.is_receiver || msg.sender == msg.receiver) &&
                    !this.waiting_for_read_list.includes(msg.uuid)
                ) {
                    console.log("reading msg", msg.uuid);
                    this.waiting_for_read_list.push(msg.uuid);
                    this.mail_store.readMessage(msg)
                        .finally(() => {
                            this.waiting_for_read_list = this.waiting_for_read_list.filter(
                                (uuid) => uuid != msg.uuid
                            );
                        });
                }
            });
        },
        contact() {
            this.refreshChat();
        },
    },
    methods: {
        scrollChatToBottom() {
            var objDiv = this.$refs.chat;
            if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        },
        refreshChat(silent = false, count = 30) {
            if (!this.contact) return
            this.last_count = count
            if (!silent) this.loading_chat = true;
            this.mail_store.loadChat(this.contact, count)
                .catch(() => console.log("[!] Can't load chat"))
                .finally(() => {
                    this.loading_chat = false;
                });
        },
        load_more() {
            this.refreshChat(false, this.last_count + 30)
        },
    },
    mounted() {
        this.interval = setInterval(() => this.refreshChat(true, this.last_count), 10000);
    },
    beforeUnmount() {
        console.log("killing chat.vue interval", clearInterval(this.interval));
    },
};
</script>