<template>
    <v-container class="noti-box">
        <v-layout column>
            <div class="d-flex flex-column justify-end">
                <v-alert v-for="not in notifications" :key="not.uuid" :model-value="true" :type="not.color || 'info'"
                    @click="destroy(not)" bottom right class="noti ma-1" variant="tonal" min-width="350"> {{ not.text }}
                </v-alert>
            </div>
        </v-layout>
    </v-container>
</template>
<style lang="scss" scoped>
.noti {
    pointer-events: auto;
}

.noti-box {
    width: 400px;
    pointer-events: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
}
</style>
<script>

export default {
    name: "snackbar",
    data: () => ({
        show: true,
        notifications: [],
    }),
    computed: {},
    methods: {
        destroy(index) {
            this.notifications.splice(index, 1);
        },
        test_not() {
            this.notificate({
                text: "Testing notifications!",
                color: "error",
                timeout: 5000,
            });
        },
        notificate(n) {
            // console.log("NOTIFICATION:", { ...n, uuid: Date.now() });
            let duplicate = false;
            this.notifications.forEach((on) => {
                if (n.text == on.text && n.color == on.color) {
                    duplicate = true;
                }
            });
            if (!duplicate) {
                this.notifications.push({ ...n, uuid: Date.now() });
                setTimeout(() => this.destroy(n), n.timeout ? n.timeout : 6000);
            }
        },
    },
    mounted() {
        this.$bus.on("notification", (n) => this.notificate(n));
    },
    beforeUnmount() {
        this.$bus.off("notification");
    },
};
// EMIT NOTIFICATIONS LIKE THIS (IN COMPONENTS OPTIONS API)
// this.$bus.emit('notification', {timeout: 5000, text:"Testing notifications!", color:"success"})

// AND LIKE THIS OUTSIDE COMPONENTS
// import bus from '/src/utils/event_bus'
// bus.emit('notification', {timeout: 5000, text:"Testing notifications!", color:"success"})

</script>
