import { backend_api_v2, paginated_factory_v2 } from '/src/utils/apiv2'
import bus from '/src/utils/event_bus'
import { defineStore } from 'pinia'

function get_or_create_chat(state, nmsg) {
    let chat = state.chats.find(chat => chat.contact == nmsg.contact.id)
    if (!chat) {
        chat = { contact: nmsg.contact.id, contact_name: nmsg.contact.name, messages: [] }
        state.chats.push(chat)
    }
    return chat
}

export const useMailStore = defineStore('mail', {
    state: () => ({
        unread_count: 0,
        chats: [
            // {contact: 1, contact_name: "fede", messages: []}
        ],
    }),
    getters: {
        getChatByContactId: state => contact_id => state.chats.find(chat => chat.contact == contact_id),
    },
    actions: {
        cleanChat(contact_id) {
            let chat = this.chats.find(chat => chat.contact == contact_id)
            console.log("cleanChat", contact_id, chat)
            if (chat) chat.messages = [{ text: "---", contact: {} }]
        },
        parseMessages(data) {
            let new_counter = 0
            data.forEach(nmsg => {
                let chat = get_or_create_chat(this, nmsg)
                let i = chat.messages.findIndex(msg => msg.uuid == nmsg.uuid)
                if (i == -1) {
                    chat.messages.push(nmsg)
                    if (!nmsg.readed && !nmsg.contact.is_receiver) new_counter += 1
                }
                else chat.messages[i] = nmsg
                chat.messages = chat.messages.slice().sort((a, b) => new Date(a.created) - new Date(b.created))
            })
            if (new_counter)
                bus.emit('notification', { timeout: 3000, text: `${new_counter} new message(s)`, color: "info" })
        },
        cleanMessages() {
            this.chats.forEach((chat) => {
                const lastMessage = chat.messages[chat.messages.length - 1];

                if (lastMessage && lastMessage.text === "---") {
                    chat.messages = [];
                }
            });
        },
        updateMessage(nmsg) {
            let chat = get_or_create_chat(this, nmsg)
            let i = chat.messages.findIndex(msg => msg.uuid == nmsg.uuid)
            if (i == -1) chat.messages.push(nmsg)
            else chat.messages[i] = nmsg
            chat.messages = chat.messages.filter(msg => !!msg.uuid).sort((a, b) => new Date(a.created) - new Date(b.created)) // remove if not uuid
        },
        removeMessage(nmsg) {
            let chat = get_or_create_chat(this, nmsg)
            chat.messages = chat.messages
                .filter(msg => nmsg.uuid != msg.uuid)
                .sort((a, b) => new Date(a.created) - new Date(b.created))
            // remove if not uuid
        },
        setUnreadCount(count) { this.unread_count = count },


        loadLastMessages() {
            return backend_api_v2.get('/mail/messages/last_of_each/')
                .then(({ data }) => {
                    // console.log("loadLastMessages:", data[1].text);
                    this.cleanMessages(data)
                    // console.log("loadLastMessages:", data[1].text);
                    this.parseMessages(data)
                })
                .catch(e => console.log(`Cant load last messages: ${e}`))
        },
        loadChat(user_id, count) {
            return paginated_factory_v2(`/mail/messages/?last=${count}&other=${user_id}`)
                .then(({ data }) => {
                    this.parseMessages(data)
                })
                .catch(e => console.log(`Cant load chat: ${e}`))
        },
        loadUnreadCount() {
            return backend_api_v2.get('/mail/messages/unread_count/')
                .then(({ data }) => {
                    this.setUnreadCount(data.count);
                })
                .catch(e => console.log(`Cant load unread count: ${e}`))
        },
        sendMessage(msg) {
            this.parseMessages([msg])
            return backend_api_v2.post('/mail/messages/', msg)
                .then(({ data }) => {
                    this.updateMessage(data)
                })
                .catch(e => console.log(`Cant load last messages: ${e}`))
        },
        readMessage(msg) {
            return backend_api_v2.put('/mail/messages/' + msg.uuid + "/", { ...msg, readed: true, delivered: true })
                .then(({ data }) => {
                    this.updateMessage(data)
                })
                .catch(e => console.log(`Cant read message: ${e}`))
        },
        deleteMessage(msg) {
            return backend_api_v2.delete('/mail/messages/' + msg.uuid + "/")
                .then(() => this.removeMessage(msg))
                .catch(e => console.log(`Cant delete message: ${e}`))
        }
    },
})
