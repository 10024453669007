<template>
    <v-list>
        <v-list-item :model-value="modelValue" v-if="chats.length !== 0" v-for="(chat, index) in chats"
            :key="chat.contact" @click="setSelected(chat.contact)">
            <template v-slot:prepend>
                <v-avatar color="grey-lighten-1">
                    <v-icon color="white">mdi-message</v-icon>
                </v-avatar>
            </template>

            <v-list-item-title>{{ chat.contact_name }}</v-list-item-title>

            <v-list-item-subtitle :class="!chat.last_message.readed &&
                !chat.last_message.contact.is_receiver
                ? 'font-weight-black'
                : ''">
                {{ chat.last_message.text }}
            </v-list-item-subtitle>
            <template v-slot:append v-if="modelValue == chat.contact">
                <MenuChatActions :chat="chat" />
            </template>
        </v-list-item>
        <v-list-item v-else>
            <v-list-item>
                <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </v-list-item>
        </v-list-item>
    </v-list>
</template>

<script>
import { useMailStore } from "@/stores/mail";
import { usePitStoreBasics } from "@/stores/app_common";
import MenuChatActions from "@/components/chat/MenuChatActions.vue";

export default {
    setup() { return { ...usePitStoreBasics(), mail_store: useMailStore() } },
    props: ["modelValue"],
    components: { MenuChatActions },
    computed: {
        chats() {
            return this.mail_store.chats
                .map((chat) => ({
                    ...chat,
                    last_message: chat.messages.slice(-1)[0],
                }))
                .sort(
                    (a, b) =>
                        new Date(b.last_message.created) -
                        new Date(a.last_message.created)
                );
        },
    },
    methods: {
        setSelected(contact_id) {
            this.$emit("update:modelValue", contact_id);
        },
    },
};
</script>